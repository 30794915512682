<template>
  <div class="common">
    <div v-if="content.tag" class="common__infos__tag tiny">
      {{ content.tag
      }}<span v-if="content.date" class="bg-grey">{{ content.date }}</span>
    </div>

    <RouterLink class="common__infos__title" :to="content.url">
      <div v-html="content.title"></div>
    </RouterLink>

    <ul v-if="content.taxonomy" class="common__infos__taxonomy">
      <li
        v-for="taxonomy in Object.keys(content.taxonomy)"
        :key="taxonomy"
        class="common__infos__taxonomy__item"
      >
        <RouterLink
          :to="`${route.path}?tag[]=${taxonomy}`"
          class="tiny-category--bordered bg-grey"
        >
          {{ content.taxonomy[taxonomy] }}
        </RouterLink>
      </li>
    </ul>

    <div class="common__picture">
      <img
        v-if="content.picture"
        v-src="content.picture"
        :alt="content.picture.alt"
        :sets="
          isBig
            ? ['240', '320', '480', '640', '960']
            : ['240', '320', '480', '640']
        "
        :sizes="isBig ? '50vw' : '(min-width: 1024px) 33vw, 50vw'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue'
import { useRoute } from 'vue-router'

import type { PropType } from 'vue'
import type { Picture } from '@/types'

export interface CardCommon {
  picture: Picture
  tag: string
  title: string
  url: string
  taxonomy: Record<string, string>
  date?: string
}

defineProps({
  content: {
    type: Object as PropType<CardCommon>,
    required: true,
  },
})

const attrs = useAttrs()
const isBig = (attrs.class as string | undefined)?.includes('is-big')
const route = useRoute()
</script>

<style lang="scss" scoped>
$bp: 'l';

.common {
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: calc(30% - 1.4rem) calc(70% - 1.4rem);
  align-items: center;
  gap: 1.4rem;

  &.is-big {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  @include mq($bp) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.6rem;

    &.is-big {
      display: grid;
      grid-template-rows: 1fr auto auto auto 1fr;
      grid-template-columns: calc(60% - 6rem) calc(40% - 6rem);
      align-items: center;
      margin-bottom: 3rem;
      gap: 0 6rem;
    }
  }
}

.common__infos__tag {
  color: $c-cobalt;

  span {
    margin-left: 0.4rem;
    padding-left: 0.4rem;
    color: var(--c-accent-60);
    border-left: 0.1rem solid var(--c-accent-20);
  }

  @include mq($bp) {
    .is-big & {
      grid-row: 2/3;
      align-self: self-end;
    }
  }
}

.common__infos__title {
  color: var(--c-text);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.6rem;

  .is-big & {
    @include mq($bp) {
      grid-row: 3/4;
      padding-bottom: 6rem;
      font-size: 3rem;
      line-height: 4.4rem;
    }
  }

  &::after {
    @include get-all-space;

    content: '';
  }
}

.common__infos__taxonomy {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  align-self: self-start;
  overflow: hidden;
  height: 2rem;
  margin-top: $spacing;
  gap: 0.4rem;
  white-space: nowrap;

  @include mq($bp) {
    .is-big & {
      grid-row: 4/5;
    }
  }
}

.common__infos__taxonomy__item {
  display: flex;
  align-items: baseline;
  background: $c-white;

  a {
    z-index: 1;
  }

  &::after {
    @extend %tiny;

    content: '...';
    display: inline-block;
    width: 1.2rem;
    margin-left: 0.4rem;
    color: var(--c-accent);
  }

  &:not(:first-child) {
    margin-left: -1.6rem;
  }

  &:last-child::after {
    content: none;
  }
}

.common__picture {
  grid-row: 1/4;
  grid-column: 1/2;
  order: -1;
  overflow: hidden;
  border-radius: 2rem;
  aspect-ratio: 1;

  .is-big & {
    aspect-ratio: 16/9;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.2s ease-in-out;
  }

  .common__infos__title:hover ~ &,
  .common__infos__title:focus-visible ~ & {
    img {
      transform: scale(1.1);
    }
  }

  @include mq($bp) {
    aspect-ratio: 16 / 9;

    .is-big & {
      grid-row: 1/6;
      border-radius: 4rem;
    }
  }
}
</style>
